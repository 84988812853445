import { ChainId } from '@pancakeswap-libs/sdk'
import MULTICALL_ABI from './abi.json'

const MULTICALL_NETWORKS: { [chainId in ChainId]: string } = {
  [ChainId.MAINNET]: '0xa1B2b503959aedD81512C37e9dce48164ec6a94d', // TODO
  [ChainId.BSCTESTNET]: '0x301907b5835a2d723Fe3e9E8C5Bc5375d5c1236A',
  [ChainId.MATICTEST]: '0x06A0575A64593c1dD497020bC80C8c4663BeaF4D',

}

export { MULTICALL_ABI, MULTICALL_NETWORKS }
